.App {
  text-align: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}


.avatar {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}


.avatar > span {
  margin-top: 1rem;
  font-size: 21pt;
  color:white
}

.avatar > img {
  width: 128px;
  border-radius: 50%;
}

.btn {
  background: #faebd778;
  /* width: 100%; */
  padding: 1rem;
  margin-top: 1rem;
  border: 2px solid #eee;
  color: white;
  font-weight: 400;
  font-size: 21px;
  cursor: pointer;
  transition: 150ms all ease;
}


.btn:hover{

  background: #ffffff;
  color: black;

}

.panel {
  display: flex;
  flex-direction: column;
}


.loading{
  font-size: 26pt;
  color:white;
}
