.non_land{
    background-image: linear-gradient(to bottom, rgb(199, 107, 157) 0%, rgb(234, 144, 111) 100%);
    color: #eeeeee;
    font-family: 'Nunito', sans-serif;
    font-weight: 100;
    height: 100vh;
    margin: 0;
}

.flex-center{
        align-items: center;
        display: flex;
        justify-content: center;
}
.full-height {
    height: 100vh;
}

.position-ref {
    position: relative;
}

.code {
    border-right: 2px solid;
    font-size: 26px;
    padding: 0 15px 0 15px;
    text-align: center;
}

.message {
    font-size: 18px;
    text-align: center;
}